import '../../css/App.css';
import React from 'react';
import logo from "../../resources/logo.png";
import {Layout, Menu} from "antd";
import {Link} from "react-router-dom";
import {DashboardOutlined, DatabaseOutlined, InfoCircleOutlined, SearchOutlined, TeamOutlined, MailOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import {withRouter} from "react-router";

const {Sider} = Layout;

/**
 * @author Yavuz
 * @version 0.1
 * 
 * Sidemenu is loaded here.
 **/

// Controller function for the sidebar, with correct redirections.
class SideMenu extends React.Component {
    state = {
        collapsed: false,
        theme: 'dark',
        current: '/dashboard',
    };

    // The current page is set on click.
    handleClick = e => {
        this.setState({current: e.key});
    };

    render() {
        // History queue of previous pages is saved in order to be able to go back to the previous page.
        const {history} = this.props;

        return (
            <Sider breakpoint="lg" collapsedWidth="80" collapsedHeigth="200">

                {/* Logo of website */}
                <div>
                    <Link to="/dashboard">
                        <img className="applogo" alt={"AYR Logo"} src={logo}/>
                    </Link>
                </div>

                <Menu theme={this.state.theme} onClick={this.handleClick} style={{minHeight: '100vh'}} defaultOpenKeys={['/1']} selectedKeys={[history.location.pathname]} mode="inline">
                    <Menu.Item key="/dashboard" icon={<DashboardOutlined/>}>
                        <Link to="/dashboard"/>
                        Dashboard
                    </Menu.Item>
                    <Menu.Item key="/search" icon={<SearchOutlined/>}>
                        <Link to="/search"/>
                        Search
                    </Menu.Item>
                    <Menu.Item key="/speakers" icon={<TeamOutlined/>}>
                        <Link to="/speakers"/>
                        Speaker list
                    </Menu.Item>
                    <Menu.Item key="/about" icon={<InfoCircleOutlined/>}>
                        <Link to="/about"/>
                        About
                    </Menu.Item>
                    <Menu.Item key="/faq" icon={<QuestionCircleOutlined/>}>
                        <Link to="/faq"/>
                        FAQ
                    </Menu.Item>
                    <Menu.Item key="/data" icon={<DatabaseOutlined/>}>
                        <Link to="/data"/>
                        Data Source
                    </Menu.Item>
                    <Menu.Item key="/contact" icon={<MailOutlined />}>
                        <Link to="/contact"/>
                        Contact
                    </Menu.Item>
                </Menu>
            </Sider>
        );
    };
};

export default withRouter(SideMenu);
