import './css/App.css';
import React from 'react';
import {FloatButton, Layout} from 'antd';
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import About from "./routes/About";
import Contact from "./routes/Contact";
import Data from "./routes/Data";
import DashboardAYR from "./routes/DashboardAYR";
import Debates from './routes/Debates';
import Faq from "./routes/Faq";
import HeaderSearch from "./components/headers/HeaderSearch";
import Search from "./routes/Search";
import Session from "./routes/Session";
import SideBar from './components/menus/SideMenu';
import Speakers from "./routes/Speakers";
import Speeches from "./routes/Speeches";

const {Content, Footer} = Layout;

/**
 * @author Yavuz
 * @version 0.1
 *
 * This is where the React applications starts. The routing and initializing of components are done here. The user interface
 * layout has been built using the Ant Design library, and for the routing we have used React-route. 
 **/

// Main App is componentized, this way we can load new components in without coding in here.
class App extends React.Component {
  render() {
    return (
        // BrowserRouter is used for creating routes for pages
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <FloatButton.BackTop visibilityHeight={50}/>
        <Layout>

          {/* Sidebar is initialized */}
          <SideBar/>
          <Layout className="site-layout">

            {/* Headers for each page are determined */}
            <Switch>
              <Route path="/dashboard" component={HeaderSearch}/>
              <Route path="/search" component={HeaderSearch}/>
              <Route path="/speeches" component={HeaderSearch}/>
              <Route path="/debates" component={HeaderSearch}/>
              <Route path="/session" component={HeaderSearch}/>
              <Route path="/speakers" component={HeaderSearch}/>
              <Route path="/about" component={HeaderSearch}/>
              <Route path="/faq" component={HeaderSearch}/>
              <Route path="/data" component={HeaderSearch}/>
              <Route path="/contact" component={HeaderSearch}/>
              <Redirect to="/dashboard"/>
            </Switch>

            {/* Correct pages are loaded */}
            <Content style={{margin: '0 16px'}}>
              <Switch>
                <Route path="/dashboard" component={DashboardAYR}/>
                <Route path="/search" component={Search}/>
                <Route path="/speeches" component={Speeches}/>
                <Route path="/debates" component={Debates}/>
                <Route path="/session" component={Session}/>
                <Route path="/speakers" component={Speakers}/>
                <Route path="/about" component={About}/>
                <Route path="/faq" component={Faq}/>
                <Route path="/data" component={Data}/>
                <Route path="/contact" component={Contact}/>
                <Redirect to="/dashboard"/>
              </Switch>
            </Content>

            <Footer className="smallCardContent" style={{textAlign: 'center', color: "#5a5c69"}}>AreYouRepresented 2023</Footer>
          </Layout>
        </Layout>
      </BrowserRouter>
    );
  }
};

export default App; 