import React, {Component} from 'react';
import axios from "axios";
import queryString from 'query-string'
import {Pagination, Skeleton} from "antd";
import Moment from 'moment';

let translatedstring = "";
let url;

/**
 * @author Yavuz
 * @version 0.1
 * 
 * The speeches result page is loaded and initialized in here.
 **/

// Helper function to validate data.
const ValidityChecker = (value) => {
    if (typeof(value) !== 'undefined') {
        return value[0];
    } else {
        return "";
    }
};

// Helper function to remove paragraphs if translated text and source text is the same.
const SimilarSpeechDataChecker = (value) => {
    if (value._source.Text === value._source.Text_tr) {
        return "";
    } else {
        return "<br/> <i>Original source text: " + value.highlight.Text + "</i>";
    }
};

// Helper function to remove dates from titles to battle double date on page.
const re = /,?\s(?:\(|\b)(\d{4}|\d{1,2})[/.-](\d{1,2})[/.-](\d{4}|\d{2})(?:\)|\b)/;
const TitleDateCleaner = (value) => {
    return value.replace(re, "");
};

// Some parties are unknown, this removes them.
const UnknownRemoverPS = (value) => {
    if (value !== "Unknown") {
        return " (" + value + ")";
    } else {
        return "";
    }
};

// This function retrieves the current parameters from the url.
const currentStateParameters = () => {
    const parsed = queryString.parse(document.location.search);
    return parsed;
};

// The final speeches class, which handles the result page if speeches are selected. 
// Speeches are retrieved from the elasticsaerch backend using fastapi.
class Speeches extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            result: null,
            divs: [],
            pagelength: null,
            totalresults: null,
            page: null,
            pages: null,
            q: null,
            error: null,
            queryforresult: "",
        }
    };
    
    // Async method which retrieves the results from the backend using the given parameters in the url.
    // After that, the loading status of the page is set to false which now renders the page.
    DataLoader() {
        let parsedparams = currentStateParameters();
        let finalstring = "";
        let finalquery = "";
        let pagequery = "";
        let translated = false;
        for (let i = 0; i < Object.keys(parsedparams).length; i++) {
            if (Object.keys(parsedparams)[i] === "q") {
                finalquery = finalquery + "?q=" + parsedparams[Object.keys(parsedparams)[i]];
                this.setState({queryforresult: parsedparams[Object.keys(parsedparams)[i]]});
            } else if (Object.keys(parsedparams)[i] === "p") {
                pagequery = pagequery + "&p=" + parsedparams[Object.keys(parsedparams)[i]];
            } else {
                if (Object.keys(parsedparams)[i] === "trl" && parsedparams[Object.keys(parsedparams)[i]] === "true") {
                    translated = true;
                    translatedstring = "translated";
                } 
            finalstring = finalstring + "&" + Object.keys(parsedparams)[i] + "=" + parsedparams[Object.keys(parsedparams)[i]];
            }
        }

        const baseurl = "https://api.areyourepresented.eu/search/";
        let final = finalquery + finalstring;
        const APIurl = baseurl + final + pagequery;
        url = "https://areyourepresented.eu/speeches/" + final;
        const DataRetriever = async () => {
            const data = await axios.get(APIurl);
            try {
                this.setState({result: data.data.result.hits.hits, totalresults: data.data.result.hits.total.value, pagelength: data.data.pagelength, page: data.data.page, pages: data.data.pages, q: data.data.q});
            } catch (err) {
                // Handle errors here.
                console.error(err);
                this.setState({error: data.data});
            } finally {
                this.setState({loading: false});

                for (let i = 0; i < this.state.result.length; i++) {
                    const TempArray = [...this.state.divs];
                    // const temper = <span class="fi fi-tr"></span>;
                    let currentresult;
                    if (translated) {
                        currentresult = "<img src=https://flagcdn.com/16x12/" + this.state.result[i]._index +".png alt=" + String("flag_" + this.state.result[0]._index) +"> <a href=../session/?text_ID=" + this.state.result[i]._source.ID + "&trl=true>" + TitleDateCleaner(this.state.result[i]._source.Title) + "</a> <i>" + Moment(this.state.result[i]._source.From).format('D MMM YYYY') + "  </i> <br/> <b> <a href=../speeches/?q=" + this.state.result[i]._source.Speaker_name.replaceAll(" ", "%20") + "&mode=speaker&sort=n&trl=true>" + this.state.result[i]._source.Speaker_name + "</a>" + UnknownRemoverPS(this.state.result[i]._source.Speaker_party) + ": </b>" + ValidityChecker(this.state.result[i].highlight.Text_tr) + SimilarSpeechDataChecker(this.state.result[i]) + "<br/><br/>";
                    } else {
                        currentresult = "<img src=https://flagcdn.com/16x12/" + this.state.result[i]._index +".png alt=" + String("flag_" + this.state.result[0]._index) +"> <a href=../session/?text_ID=" + this.state.result[i]._source.ID + ">" + TitleDateCleaner(this.state.result[i]._source.Title) + "</a> <i>" + Moment(this.state.result[i]._source.From).format('D MMM YYYY') +  "</i> <br/> <b> <a href=../speeches/?q=" + this.state.result[i]._source.Speaker_name.replaceAll(" ", "%20") + "&mode=speaker&sort=n>" + this.state.result[i]._source.Speaker_name + "</a>" + UnknownRemoverPS(this.state.result[i]._source.Speaker_party) + ": </b>" + this.state.result[i].highlight.Text[0] + " <br/><br/></div>";
                    }
                    TempArray.push(<div className="speakersmallcardcontent" dangerouslySetInnerHTML={{ __html: currentresult}} key={i}/>);
                    console.log(currentresult)
                    this.setState({divs: TempArray});
                }
            }
        };

        DataRetriever();
    };

    // When component is mount, we make the API call.
    componentDidMount() {
        this.DataLoader();
    };

    // If there is another error than no hits, display that error as well.
    errorChecker() {
        if (this.state.error !== "No hits!") {
            return "Extra error information: " + this.state.error;
        } else {
            return "";
        }
    };

    // The final render is done, consisting of a title, and the results.
    render() {
        if (this.state.result !== null) {
            return (
                <Skeleton active={true} loading={this.state.loading}>
                    <h3 className="headhsspeakersmallcardcontent">Showing results on {translatedstring} speech {1+((this.state.page-1)*(this.state.pagelength))} - {((this.state.page-1)*(this.state.pagelength)) + this.state.divs.length} of {this.state.totalresults} for query <u>{this.state.q}</u>:</h3>
                    <div>
                        {this.state.divs}
                        <br></br>
                        <br></br>
                    </div>

                    <Pagination style={{margin: "auto", width: "50%"}} defaultCurrent={this.state.page} defaultPageSize={this.state.pagelength} total={this.state.totalresults} onChange={onChange} showSizeChanger={false} showQuickJumper/>
                </Skeleton>
            );
        } else {
            return (
                <Skeleton active={true} loading={this.state.loading}>
                    <h1 className="headhsspeakersmallcardcontent">No results for query <u>{this.state.queryforresult}</u>, try another query or check your input. </h1>
                    <br></br>
                    {this.errorChecker()}
                </Skeleton>
            );
        }
    };
};

const onChange = (page) => {
    let redirecturl = url + "&p=" + page;
    window.open(redirecturl,"_self");
};

export default Speeches;