import {Card, Col, Popover, Row} from 'antd';
import {CloudOutlined, FileTextOutlined, GlobalOutlined, TranslationOutlined} from "@ant-design/icons";
import RandomDebate from "../components/dashboard/RandomDebate";

/**
 * @author Yavuz
 * @version 0.1
 * 
 * The dashboard is loaded and initiliazed here.
 **/

// The list of supported countries and translated countries are initiliazed.
const content_ct = (
  <div>
    Belgium, Bulgaria, Czechia, Denmark, Spain, France, the United Kingdom, Hungary,
    <br></br>
    Iceland, Italy, Lithuania, Latvia, the Netherlands, Poland, Slovenia, and Turkey
  </div>
);

const content_trct = (
  <div>
    Bulgarian, Czech, Danish, Hungarian, Icelandic,
    <br></br>
    Italian, Dutch, Polish, Slovene, and Turkish
  </div>
);

// The main render of the dashboard.
const App = () => {
    return (
        <>      
            <Row class="banner">
                <div class="banner">
                    <div class="row banner__row">
                        <div class="bannerstyling">
                            <div class="bannerstyling__wrap" style={{color: "white"}}>
                                <h1>Discover whether your council member represents you</h1>
                            </div>
                        </div>
                        
                        <div class="bannerstylingsite">
                            <div class="bannerstylingsite__wrap">
                                <h3>European Parliamentary Search: the Power of Data and Insight Across Multiple Countries</h3>
                                <p>Parliamentary speeches and debates should be easily accessible to everyone. European voters should have insight into their parliament so they can determine whether they are being represented.</p>
                                <p>AreYouRepresented utilizes <a href="https://www.clarin.eu/parlamint" class="bannerstylingsitelink">ParlaMint</a>'s data on European parliaments and presents a search engine for effortlessly exploring and analyzing parliamentary speeches.</p>
                                <a href="/about" class="bannerstylingsitelink">About AreYouRepresented <i>&rarr;</i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </Row>

            <Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
                <Col className="topCards" flex="auto">
                    <Popover content={content_ct} trigger="hover">
                        <Card bodyStyle={{height: 'auto'}} hoverable bordered={false}>
                            <div className="alignment">
                                <div>
                                    <div className="smallcardtitle">
                                        <span>
                                            TOTAL COUNTRIES
                                        </span>
                                    </div>

                                    <div className="smallcardcontent">
                                        <span>
                                            16
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <GlobalOutlined className="smallcardicon"/>
                        </Card>
                    </Popover>
                </Col>

                <Col className="topCards" flex="auto">
                    <Card bodyStyle={{height: 'auto'}} hoverable bordered={false}>
                        <div className="alignment">
                            <div>
                                <div className="smallcardtitle">
                                    <span>
                                        AMOUNT OF SESSIONS
                                    </span>
                                </div>

                                <div className="smallcardcontent">
                                    <span>
                                        4.801.425
                                    </span>
                                </div>
                            </div>
                        </div>

                        <FileTextOutlined className="smallcardicon"/>
                    </Card>
                </Col>
                    
                <Col className="topCards" flex="auto">
                    <Popover content={content_trct} trigger="hover">
                        <Card bodyStyle={{height: 'auto'}} hoverable bordered={false}>
                            <div className="alignment">
                                <div>
                                    <div className="smallcardtitle">
                                        <span>
                                            TRANSLATED LANGUAGES
                                        </span>
                                    </div>

                                    <div className="smallcardcontent">
                                        <span>
                                            11
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <TranslationOutlined className="smallcardicon"/>
                        </Card>
                    </Popover>
                </Col>

                <Col className="topCards" flex="auto">
                    <Card bodyStyle={{height: 'auto'}} hoverable bordered={false}>
                        <div className="alignment">
                            <div>
                                <div className="smallcardtitle">
                                    <span>
                                        SYSTEM STATUS
                                    </span>
                                </div>

                                <div className="smallcardcontent">
                                    <span>
                                        Running
                                    </span>
                                </div>
                            </div>
                        </div>

                        <CloudOutlined className="smallcardicon"/>
                    </Card>
                </Col>
            </Row>
                
            <Col className="topCards" flex="auto">
                <Card hoverable bordered={false}>
                    <div className="alignment">
                        <div>
                            <div className="smallcardtitle">
                                <span>
                                    RANDOM SPEECH
                                </span>
                            </div>

                            <div className="randomspeechcard">
                                <span>
                                    <RandomDebate/>
                                </span>
                            </div>
                        </div>
                    </div>
                </Card>
            </Col>
        </>
    );
};

export default App;