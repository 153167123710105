import React, {Component} from 'react';
import {Card, Skeleton} from "antd";

/**
 * @author Yavuz
 * @version 0.1
 * 
 * The data webpage is rendered here.
 **/

class Data extends Component {
    render() {
        return (
            <Skeleton active={true} loading={false}>    
                <Card bodyStyle={{height: 'auto'}} bordered={false} style={{marginTop: 10}}>
                    <div className="alignment">
                        <div>
                            <div className="cardtitle">
                                <span>
                                    Data Source
                                </span>
                            </div>

                            <div className="cardcontent_about">
                                <span>
                                    <p>
                                        At <span className="bluetext">AreYouRepresented</span>, we are committed to providing users with accurate and reliable parliamentary data. The data used on our platform is sourced from the <a href="https://www.clarin.eu/parlamint">ParlaMint 2.0</a> repository maintained by <a href="https://www.clarin.eu/">CLARIN</a> (Common Language Resources and Technology Infrastructure). It allows us to offer a comprehensive collection of parliamentary speeches and related metadata from multiple European countries.
                                    </p>
                                    <p>
                                        The ParlaMint repository is a valuable resource for researchers and developers, providing standardized and high-quality parliamentary corpora designed explicitly for analysis and research purposes. It encompasses speeches from parliamentary sessions across different countries, covering a wide range of topics and political discussions containing over 1.5 million speeches of more than 5.000 parliamentarians.
                                    </p>
                                    <p>
                                        Its dataset currently provides seventeen countries: Bulgaria, Croatia, Czechia, Denmark, Spain, France, United Kingdom, Hungary, Iceland, Italy, Lithuania, Latvia, United Kingdom, Netherlands, Poland, Slovenia, and Turkey. Except for Croatia, these countries are all included in this search engine. Most of the countries' corpora contain parliamentary debates starting in 2015 and extending to mid-2020, with each corpus being about 20 million words in size. Extensive metadata is available for each session, such as speaker information (name, gender, party, party status), time-stamped terms and sessions, and speeches marked by their speaker and role (such as chair or regular speaker). Marked-up transcriber comments, such as gaps in the transcription, interruptions, and applauses, are also included in this repository.
                                    </p>
                                    <p>
                                        We value transparency and open data principles, and we acknowledge the efforts of <a href="https://www.clarin.eu/">CLARIN</a> and the parliamentary institutions in providing accessible and reliable data for research and analysis. By utilizing and leveraging the ParlaMint repository, we aim to offer users a comprehensive and trustworthy platform for their parliamentary research and analysis needs.
                                    </p>
                                    <p>
                                        If you have any questions or concerns regarding the data sources used on <span className="bluetext">AreYouRepresented</span>, please feel free to <a href="/contact">contact</a> us. We are dedicated to providing accurate information and maintaining the highest data quality standards.
                                    </p>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="bannerdata"></div>
                </Card>
            </Skeleton>
        );
    };
};

export default Data;