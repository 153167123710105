import React from "react";
import axios from "axios";

/**
 * @author Yavuz
 * @version 0.1
 *
 * Component for loading a random debate on the dashboard page.
 **/

class RandomDebate extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            result: null,
        }
    };

    // This function makes an API call to the backend to retrieve data.
    DataLoader() {
        const APIurl = 'https://api.areyourepresented.eu/randomsession/?country=bg,cz,dk,gb,hu,is,it,nl,pl,si,tr'
        const DataRetriever = async () => {
            try {
                const data = await axios.get(APIurl);
                this.setState({result: data.data.result.hits.hits[0]._source});
                // In some speeches, the speaker is unknown. Better to remove those.
                if (this.state.result.Speaker_name === "-" || this.state.result.Speaker_name === "@Posłowie@") {
                    this.DataLoader();
                }
            } catch (err) {
                console.log(err);
            } finally {
                this.setState({loading: false});
            }
        };

        DataRetriever();
    };

    componentDidMount() {
        this.DataLoader();
    };

    // Make the <div> ready that is going to be sent to the frontend after retrieving a random session.
    DataChecker() {
        if (this.state.result !== null) {
            const link = '../session/?text_ID=' + this.state.result.ID + "&trl=true";
            return <div><b>{console.log(this.state.result)}{this.state.result.Speaker_name} ({this.state.result.From}): </b>{this.state.result.Text_tr} <br></br> <a href={link} class="site-intro__more-link">Read session <i>&rarr;</i></a></div>
        }
    };

    // And the final render is done.
    render() {
        return(
            this.DataChecker()
        );
    };
};

export default RandomDebate;