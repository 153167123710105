import React, {Component} from 'react';
import axios from "axios";
import {Button, Card, Checkbox, Col, Form, Row, Skeleton, Space} from 'antd';
import {GlobalOutlined, UnorderedListOutlined} from "@ant-design/icons";

let countrylist = { "be" : "Belgium", "bg" : "Bulgaria", "dk" : "Denmark", "lt" : "Lithuania", "lv" : "Latvia", "is" : "Iceland", "it" : "Italy", "fr" : "France", "hu" : "Hungary", "es" : "Spain", "si" : "Slovenia", "cz" : "Czechia", "gb" : "United Kingdom", "pl" : "Poland", "nl" : "Netherlands", "tr" : "Turkey"};
let gender = { "M" : "male", "F" : "female", "-" : "unknown gender"};
const formItemLayout = { labelCol: { span: 6 }, wrapperCol: { span: 14 } };

/**
 * @author Yavuz
 * @version 0.1
 * 
 * The list of speakers in loaded and initliazed here.
 **/

// This class retrieves a speaker list from the backend, but also has a filtering for countries
// and a next page button of the results.
class Speakers extends Component {
  constructor(props) {
      super(props);
      this.handleForceupdateMethod = this.handleForceupdateMethod.bind(this);
      this.pager = this.pager.bind(this);

      this.state = {
          loading: true,
          result: null,
          divs: null,
          error: "",
          after_key: null,
          values: null,
      }
  };
    
  // Async method which retrieves the results from the backend using the country filters.
  // After that, the page is rendered.
  DataLoader(val, key) {
    if (key === null || typeof(key) === "undefined") {
        key = "";
    } else {
        key = "&after=" + key.replaceAll(" ", "%20");
    }

    this.setState({values: val, divs : []});
    this.error = "";

    let countries = "?country=";
    if (val === "init") {
        countries = countries + "all";
    } else if (val.length === 16) {
        countries = countries + "all";
    } else if (val.length === 0) {
        countries = countries + "none";
        this.error = "Error: you have not selected any countries!";
    } else {
        countries = countries + val;
    }
            
    const baseurl = "https://api.areyourepresented.eu/speakers/";
    let APIurl = baseurl + countries + key;
    const DataRetriever = async () => {
      const data = await axios.get(APIurl);
      try {
        this.setState({result: data.data.result.aggregations.names.buckets, after_key: data.data.result.aggregations.names.after_key.name});
      } catch (err) {
        // Handle errors here.
        console.error(err);
        this.setState({error: data.data});
      } finally {
        this.setState({loading: false});

        if (this.error === "") {
          for (let i = 0; i < this.state.result.length; i++) {
              const TempArray = [...this.state.divs];
              let currentresult = "<a href=../speeches/?q=" + this.state.result[i].key.name.replaceAll(" ", "%20") + "&mode=speaker&sort=n>" + this.state.result[i].key.name + "</a> (<i>Gender:</i> " + gender[this.state.result[i].hits.hits.hits[0]._source.Speaker_gender] +", <i>party:</i> " + this.state.result[i].hits.hits.hits[0]._source.Speaker_party_name + ") from <b>" + countrylist[this.state.result[i].hits.hits.hits[0]._index] + "</b>";
              TempArray.push(<div dangerouslySetInnerHTML={{ __html: currentresult}} key={i}/>);
              this.setState({divs: TempArray});
          }
        } else {
            this.setState({divs: [this.error]});
        }
      }
    };

    DataRetriever();
  };

  // When component is mount, we make the API call.
  componentDidMount() {
      this.DataLoader("init");
  };

  // When filtered, the component is updated with the new filter.
  handleForceupdateMethod(values) {
      this.DataLoader(values['country'], null);
      this.forceUpdate();
  };

  // Next page updater with a button.
  pager() {
      this.DataLoader(this.state.values, this.state.after_key);
      this.forceUpdate();
  };

  // The final page is rendered.
  render() {
    return (
      <>      
        <Col className="topCards" flex="auto" style={{marginTop: 10}}>
          <Card bodyStyle={{height: 'auto'}} hoverable bordered={false}>
              <div className="alignmentspeakers">
                  <div>
                    <div className="smallcardtitle">
                      <span>
                          COUNTRY FILTER
                      </span>
                    </div>

                    <div className="searchsmallcardcontent"></div>
                    
                    <Form {...formItemLayout} onFinish={this.handleForceupdateMethod} initialValues={{'country': ['be','bg','cz','dk','es','fr','gb','hu','is','it','lt','lv','nl','pl','si','tr']}} style={{maxWidth: 1000}}>    
                      <Row>
                        <Form.Item name="country">
                          <Checkbox.Group>
                            <Row>
                              <Col span={6}>
                                <Checkbox value="be" style={{lineHeight: '32px'}}>
                                    Belgium
                                </Checkbox>
                              </Col>
                              
                              <Col span={6}>
                                <Checkbox value="bg" style={{lineHeight: '32px'}}>
                                  Bulgaria
                                </Checkbox>
                              </Col>

                              <Col span={6}>
                                <Checkbox value="cz" style={{lineHeight: '32px'}}>
                                  Czechia
                                </Checkbox>
                              </Col>
                              
                              <Col span={6}>
                                <Checkbox value="dk" style={{lineHeight: '32px'}}>
                                  Denmark
                                </Checkbox>
                              </Col>

                              <Col span={6}>
                                <Checkbox value="es" style={{lineHeight: '32px'}}>
                                  Spain
                                </Checkbox>
                              </Col>

                              <Col span={6}>
                                <Checkbox value="fr" style={{lineHeight: '32px'}}>
                                  France
                                </Checkbox>
                              </Col>

                              <Col span={6}>
                                <Checkbox value="gb" style={{lineHeight: '32px'}}>
                                  United Kingdom
                                </Checkbox>
                              </Col>

                              <Col span={6}>
                                <Checkbox value="hu" style={{lineHeight: '32px'}}>
                                  Hungary
                                </Checkbox>
                              </Col>

                              <Col span={6}>
                                <Checkbox value="is" style={{lineHeight: '32px'}}>
                                  Iceland
                                </Checkbox>
                              </Col>

                              <Col span={6}>
                                <Checkbox value="it" style={{lineHeight: '32px'}}>
                                  Italy
                                </Checkbox>
                              </Col>

                              <Col span={6}>
                                <Checkbox value="lt" style={{lineHeight: '32px'}}>
                                  Lithuania
                                </Checkbox>
                              </Col>

                              <Col span={6}>
                                <Checkbox value="lv" style={{lineHeight: '32px'}}>
                                  Latvia
                                </Checkbox>
                              </Col>

                              <Col span={6}>
                                <Checkbox value="nl" style={{lineHeight: '32px'}}>
                                  Netherlands
                                </Checkbox>
                              </Col>

                              <Col span={6}>
                                <Checkbox value="pl" style={{lineHeight: '32px'}}>
                                  Poland
                                </Checkbox>
                              </Col>

                              <Col span={6}>
                                <Checkbox value="si" style={{lineHeight: '32px'}}>
                                  Slovenia
                                </Checkbox>
                              </Col>

                              <Col span={6}>
                                <Checkbox value="tr" style={{lineHeight: '32px'}}>
                                  Turkey
                                </Checkbox>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </Row>
                    
                      <Form.Item style={{float: "left"}} wrapperCol={{span: 12}}>
                        <Space>
                          <Button type="primary" htmlType="submit">Submit</Button>
                          <Button htmlType="reset">Reset</Button>
                        </Space>
                      </Form.Item>
                    </Form>    
                  </div>
              </div>
              
              <GlobalOutlined className="smallcardicon"/>
            </Card>
        </Col>
        
        <Skeleton active={true} loading={this.state.loading}>
          <Card bodyStyle={{height: 'auto'}} hoverable bordered={false}>
            <div className="alignmentspeakers2">
              <div>
                <div className="smallcardtitle">
                  <Form.Item>
                    <Button type="dashed" onClick={this.pager}>Next page</Button>
                  </Form.Item>
                </div>

                <div className="speakersmallcardcontent">
                  <span>
                    {this.state.divs}
                  </span>
                </div>
              </div>
            </div>
            
            <UnorderedListOutlined className="smallcardicon"/>
          </Card>
        </Skeleton>  
      </>
    );
  };
};

export default Speakers;