import React, {Component} from 'react';
import axios from "axios";
import queryString from 'query-string';
import {Pagination, Skeleton} from "antd";
import Moment from 'moment';

const countries = { "be" : "Belgium", "bg" : "Bulgaria", "dk" : "Denmark", "lt" : "Lithuania", "lv" : "Latvia", "is" : "Iceland", "it" : "Italy", "fr" : "France", "hu" : "Hungary", "es" : "Spain", "si" : "Slovenia", "cz" : "Czechia", "gb" : "United Kingdom", "pl" : "Poland", "nl" : "Netherlands", "tr" : "Turkey"};
let translatedstring = "";
let url;

/**
 * @author Yavuz
 * @version 0.1
 * 
 * The debate topics result page is loaded and initialized in here.
 **/

// Helper function to remove paragraphs if translated text and source text is the same.
const SimilarDebateDataChecker = (value) => {
    if (value._source.Debatetopic === value._source.Debatetopic_tr) {
        return "";
    } else {
        return "<br/> <i>Original source debate topic: " + value.highlight.Debatetopic + "</i>";
    }
};

// This function retrieves the current parameters from the url.
const currentStateParameters = () => {
    const parsed = queryString.parse(document.location.search);
    return parsed;
}

// The final debates class, which handles the result page if debate topics are secleted. 
// Debate topics are retrieved from the elasticsaerch backend using fastapi.
class Debates extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            result: null,
            divs: [],
            pagelength: null,
            totalresults: null,
            page: null,
            pages: null,
            q: null,
            error: null,
            queryforresult: "",
        }
    };
    
    // Async method which retrieves the results from the backend using the given parameters in the url.
    // After that, the loading status of the page is set to false which now renders the page.
    DataLoader() {
        let parsedparams = currentStateParameters();
        let finalstring = "";
        let finalquery = "";
        let pagequery = "";
        let translated = false;
        for (let i = 0; i < Object.keys(parsedparams).length; i++) {
            if (Object.keys(parsedparams)[i] === "q") {
                finalquery = finalquery + "?q=" + parsedparams[Object.keys(parsedparams)[i]];
                this.setState({queryforresult: parsedparams[Object.keys(parsedparams)[i]]});
            } else if (Object.keys(parsedparams)[i] === "p") {
                pagequery = pagequery + "&p=" + parsedparams[Object.keys(parsedparams)[i]];
            } else {
                if (Object.keys(parsedparams)[i] === "trl" && parsedparams[Object.keys(parsedparams)[i]] === "true") {
                    translated = true;
                    translatedstring = "translated";
                }
                finalstring = finalstring + "&" + Object.keys(parsedparams)[i] + "=" + parsedparams[Object.keys(parsedparams)[i]];
            }
        }

        const baseurl = "https://api.areyourepresented.eu/search/";
        let final = finalquery + finalstring;
        const APIurl = baseurl + final + pagequery;
        url = "https://areyourepresented.eu/debates/" + final;
        const DataRetriever = async () => {
            const data = await axios.get(APIurl);
            try {
                this.setState({result: data.data.result.hits.hits, totalresults: data.data.result.aggregations.total.value, pagelength: data.data.pagelength, page: data.data.page, pages: data.data.pages, q: data.data.q});
            } catch (err) {
                // Handle errors here.
                console.error(err);
                this.setState({error: data.data});
            } finally {
                this.setState({loading: false});
                
                for (let i = 0; i < this.state.result.length; i++) {
                    const TempArray = [...this.state.divs];
                    let currentresult;
                    if (translated) {
                        currentresult = "<img src=https://flagcdn.com/16x12/" + this.state.result[i]._index +".png alt=" + String("flag_" + this.state.result[0]._index) +"> " + countries[this.state.result[i]._index] + " talked about <a href=../session/?text_ID=" + this.state.result[i]._source.ID + "&debate=true&trl=true>" + this.state.result[i].highlight.Debatetopic_tr + "</a> on <i>" + Moment(this.state.result[i]._source.From).format('D MMM YYYY') + "</i>" + SimilarDebateDataChecker(this.state.result[i]) + "<br/><br/>";
                    } else {
                        currentresult = "<img src=https://flagcdn.com/16x12/" + this.state.result[i]._index +".png alt=" + String("flag_" + this.state.result[0]._index) +"> " + countries[this.state.result[i]._index] + " talked about <a href=../session/?text_ID=" + this.state.result[i]._source.ID + "&debate=true>" + this.state.result[i].highlight.Debatetopic + "</a> on <i>" + Moment(this.state.result[i]._source.From).format('D MMM YYYY') + "</i><b><br/><b><br/>";
                    }
                    TempArray.push(<div className="speakersmallcardcontent" dangerouslySetInnerHTML={{ __html: currentresult}} key={i}/>);
                    this.setState({divs: TempArray});
                }
            }
        };

        DataRetriever();
    };

    // When component is mount, we make the API call.
    componentDidMount() {
        this.DataLoader();        
    };

    // If there is another error than no hits, display that error as well.
    errorChecker() {
        if (this.state.error !== "No hits!") {
            return "Extra error information: " + this.state.error;
        } else {
            return "";
        }
    };

    // The final render is done, consisting of a title, and the results.
    render() {
        if (this.state.result !== null) {
            return (
                <Skeleton active={true} loading={this.state.loading}>
                    <h3 className="headhsspeakersmallcardcontent">Showing results on {translatedstring} debate topic {1+((this.state.page-1)*(this.state.pagelength))} - {((this.state.page-1)*(this.state.pagelength)) + this.state.divs.length} of {this.state.totalresults} for query <u>{this.state.q}</u>:</h3>
                    <div>
                        {this.state.divs}
                        <br></br>
                        <br></br>
                    </div>

                    <Pagination style={{margin: "auto", width: "50%"}} defaultCurrent={this.state.page} defaultPageSize={this.state.pagelength} total={this.state.totalresults} onChange={onChange} showSizeChanger={false} showQuickJumper/>
                </Skeleton>
            );
        } else {
            return (
                <Skeleton active={true} loading={this.state.loading}> 
                    <h1 className="headhsspeakersmallcardcontent">No results for query <u>{this.state.queryforresult}</u>, try another query or check your input. </h1>
                    <br></br>
                    {this.errorChecker()}
                </Skeleton>
            );
        }
    };
};

const onChange = (page) => {
    let redirecturl = url + "&p=" + page;
    window.open(redirecturl,"_self");
};

export default Debates;