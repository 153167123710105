import React, {Component} from 'react';
import {Card, Skeleton} from "antd";
import {MailOutlined} from "@ant-design/icons";

/**
 * @author Yavuz
 * @version 0.1
 * 
 * This page renders the contact us page.
 **/

class Contact extends Component {
    render() {
        return (
            <Skeleton active={true} loading={false}> 
                <Card bodyStyle={{height: 'auto'}} bordered={false}  style={{marginTop: 10}}>
                    <div className="alignment">
                        <div>
                            <div className="cardtitle">
                                <span>
                                    Contact
                                </span>
                            </div>

                            <div className="cardcontent_about">
                                <span>
                                    For any questions regarding this website, first take a look at our <a href="/faq">FAQs</a>. <br></br>If you cannot find an answer to your question there, please send an e-mail to <a href="mailto:info@areyourepresented.eu">info@areyourepresented.eu</a>. <br></br> <br></br>Remember, we do not have contact with the politicians on this website. 
                                </span>
                            </div>
                        </div>
                    </div>

                    <MailOutlined className="smallcardicon"/>
                </Card>
            </Skeleton>
        );
    };
};

export default Contact;