import {Button, Card, Checkbox, Col, DatePicker, Form, Input, Radio, Row, Select, Space, Switch, Tooltip} from 'antd';
import {InfoCircleOutlined, SearchOutlined} from "@ant-design/icons";
import {useState} from 'react';

const { RangePicker } = DatePicker;
const { Option } = Select;
let disableddebate = false;
let disabledtranslation = false;

/**
 * @author Yavuz
 * @version 0.1
 * 
 * The advanced search page is initialized here. All controller functions and helper functions are also defined here.
 **/

// When the submit button is clicked, this function cleans the data and redirects it to the correct page.
const onFinish = (values) => {
  try {
    const rangeValue = values['ranges'];
    const rstart = rangeValue[0].format('YYYY-MM-DD');
    const rstop = rangeValue[1].format('YYYY-MM-DD');
    values['rstart'] = rstart;
    values['rstop'] = rstop;
    } catch (err) {
      // Handle errors here.
      console.log(err)
    }
  const debatec = ["dk", "gb", "it", "nl", "es", "fr"]; // current debate supported countries.
  const translatedc = ["bg", "cz", "dk", "gb", "hu", "is", "it", "nl", "pl", "si", "tr"]; // current translation supported countries.
  const debatetranslationc = debatec; // current translated debate supported countries.

  let searchquery = "";
  for (let i = 0; i < Object.keys(values).length; i++) {
    if (typeof(values[Object.keys(values)[i]]) !== "undefined" && Object.keys(values)[i] !== "ranges") {
      if (Object.keys(values)[i] === "q") {
        searchquery = searchquery + "?q=" + values[Object.keys(values)[i]];
      } else if (Object.keys(values)[i] === "country") {
        if (disableddebate || disabledtranslation) {
          let cleanedcountries = "";
          for (let x = 0; x < values[Object.keys(values)[i]].length; x++) {
            if (disableddebate && !disabledtranslation) {
              if (debatec.indexOf(values[Object.keys(values)[i]][x]) > -1) {
                cleanedcountries = cleanedcountries + values[Object.keys(values)[i]][x] + ",";
              }
            }
            if (!disableddebate && disabledtranslation) {
              if (translatedc.indexOf(values[Object.keys(values)[i]][x]) > -1) {
                cleanedcountries = cleanedcountries + values[Object.keys(values)[i]][x] + ",";
              }
            }
            if (disableddebate && disabledtranslation) {
              if (debatetranslationc.indexOf(values[Object.keys(values)[i]][x]) > -1) {
                cleanedcountries = cleanedcountries + values[Object.keys(values)[i]][x] + ",";
              }
            }
          }
          if (cleanedcountries.length !== 0) {
            searchquery = searchquery + "&country=" + cleanedcountries;
            searchquery = searchquery.slice(0, -1);
          }
          if (cleanedcountries.length === 0) {
            searchquery = searchquery + "&country=none";
          }
        }
        else if (values[Object.keys(values)[i]].length === 16) {
          searchquery = searchquery + "&country=all";
        } else if (values[Object.keys(values)[i]].length === 0) {
          searchquery = searchquery + "&country=none";
        } else {
          searchquery = searchquery + "&country=" + values[Object.keys(values)[i]];
        }
      } else {
      searchquery = searchquery + "&" + Object.keys(values)[i] + "=" + values[Object.keys(values)[i]];
      }
    }
  }

  if (!disableddebate) {
    window.open("speeches/"+searchquery,"_self");
  } else {
    window.open("debates/"+searchquery,"_self");
  }
};

// Helper functions for component control, some components are disabled with some search modes.
const switchSearch = () => {
  if (disableddebate) {
    disableddebate = false;
  } else {
    disableddebate = true;
  }  
};

const switchSearchTr = () => {
  if (disabledtranslation) {
    disabledtranslation = false;
  } else {
    disabledtranslation = true;
  }  
};

const componentDisabler = () => {
  if (disabledtranslation || disableddebate) {
    return true;
  } else {
    return false;
  }
};

const componentDisabler_complex = () => {
  if (!disableddebate) {
    if (disabledtranslation) {
      return true;
    }
      return false;
  } else {
    if (disabledtranslation) {
      return true;
    }
    return false;
  }
};

// Main search component where everything is rendered and controlled
const App = () => {
  const [searchType, setsearchType] = useState(false);
  const [modusDisabled, setmodusDisabled] = useState(true);
  const [, setTranslationCountries] = useState(false);
  const [rangeDisabled, setrangeDisabled] = useState(false);
  const rangeConfig = { rules: [{ required: !modusDisabled, message: 'You need to give a range!', type: 'array' }] };
  
  // Exter helper functions for the date ranger, which is live updated if range mode is selected.
  let disabledrange = false;
  const disableRange_type1 = (value) => {
    setrangeDisabled(false);
    if (value === true) {
      disabledrange = true;
    } else {
      disabledrange = false; 
    }
    return disabledrange;
  };

  const disableRange_type2 = (value) => {
    setrangeDisabled(true);
    if (value === false) {
      disabledrange = true;
    } else {
      disabledrange = false;
    }
    return disabledrange;
  };

  // The final render of the advanced search component.
  return (
    <>      
      <Col className="topCards" flex="auto" style={{marginTop: 10}}>
        <Card bodyStyle={{height: 'auto'}} hoverable bordered={false}>
            <div className="alignment">
                <div>
                    <div className="smallcardtitle">
                      <span>
                          ADVANCED SEARCH
                      </span>
                    </div>

                    <div className="searchsmallcardcontent">
                    <Form onFinish={onFinish} initialValues={{'country': ['be','bg','cz','dk','es','fr','gb','hu','is','it','lt','lv','nl','pl','si','tr']}}>    
                      <Col>
                        <Form.Item label="Query" name="q" rules={ [{ required: true, message: 'You need to write a query!' }] }>
                          <Input placeholder="e.g. a topic or person"/>
                        </Form.Item>
                      </Col>

                      <Row>
                        <Col span={6}>
                          <Row style={{flexFlow: "row"}}>
                            <Form.Item  style={{width: "15em"}} label="Search in:" name="debate">
                              <Select disabled={rangeDisabled} defaultValue="false" onChange={()=>{switchSearch()}} onClick={()=>{setsearchType(disableddebate)}}>
                                <Option value="false">speeches</Option>
                                <Option value="true">debate topics</Option>
                              </Select>
                            </Form.Item>

                            &nbsp;
                            <Tooltip title='Search in the individual speeches or debate topics of sessions.'>
                                <InfoCircleOutlined/>
                            </Tooltip>
                          </Row>
                        </Col> 
                      
                        <Col flex="auto" >
                          <Row style={{float:"right"}}>
                            <Form.Item style={{float: "right"}} name="mode" label="Search modus" rules={ [{message: 'Please pick an item!', }] }>
                              <Radio.Group defaultValue="std">
                                <Radio.Button onChange={()=>{setmodusDisabled(disableRange_type1(true))}} value="std">Standard</Radio.Button>
                                <Radio.Button onChange={()=>{setmodusDisabled(disableRange_type1(true))}} value="exact">Exact</Radio.Button>
                                <Radio.Button onChange={()=>{setmodusDisabled(disableRange_type1(false))}} value="range">Range</Radio.Button> 
                                <Radio.Button onChange={()=>{setmodusDisabled(disableRange_type2(false))}} disabled={searchType} value="speaker">Speaker</Radio.Button>
                              </Radio.Group>
                            </Form.Item>

                            &nbsp;
                            <Tooltip title='Exact modus: exact word or phrase, range modus: restrict results to particular date range, speaker modus: search on speaker name.'>
                                <InfoCircleOutlined/>
                            </Tooltip>
                          </Row>
                        </Col>
                      </Row>

                      <Col>
                        <Form.Item label="Excluding" name="exc">
                          <Input placeholder="words to exclude"/>
                        </Form.Item>
                      </Col>

                      <Row>
                        <Col>
                          <Row>
                            <Form.Item name="trl" label="Cross-lingual">
                              <Switch disabled={rangeDisabled} onChange={()=>{switchSearchTr()}} onClick={()=>{setTranslationCountries(disabledtranslation)}}/>
                            </Form.Item> 

                            &nbsp;
                            <Tooltip title='Search through translated English texts. Note: machine translated, not verified.'>
                                <InfoCircleOutlined/>
                            </Tooltip>
                          </Row>
                        </Col>

                        <Col flex="auto">
                          <Row style={{float: "right"}}>
                            <Form.Item name="sort" label="Sort by" style={{float: "right"}}>
                              <Radio.Group defaultValue="r">
                                <Radio value="r">Relevancy</Radio>
                                <Radio value="n">Newest</Radio>
                                <Radio value="o">Oldest</Radio>
                              </Radio.Group>
                            </Form.Item>

                            &nbsp;
                            <Tooltip title='How the results should be filtered.'>
                                <InfoCircleOutlined/>
                            </Tooltip>
                          </Row>
                        </Col>
                      </Row>

                      <Row>
                        <Form.Item name="country" label="Country">
                          <Checkbox.Group>
                            <Row>
                              <Col span={6}>
                                <Checkbox disabled={componentDisabler()} value="be" style={{lineHeight: '32px'}}>
                                  Belgium
                                </Checkbox>
                              </Col>

                              <Col span={6}>
                                <Checkbox disabled={searchType} value="bg" style={{lineHeight: '32px'}}>
                                  Bulgaria
                                </Checkbox>
                              </Col>

                              <Col span={6}>
                                <Checkbox disabled={searchType} value="cz" style={{lineHeight: '32px'}}>
                                  Czechia
                                </Checkbox>
                              </Col>

                              <Col span={6}>
                                <Checkbox value="dk" style={{lineHeight: '32px'}}>
                                  Denmark
                                </Checkbox>
                              </Col>

                              <Col span={6}>
                                <Checkbox disabled={componentDisabler_complex()}
                                  value="es" style={{lineHeight: '32px'}}>
                                  Spain
                                </Checkbox>
                              </Col>

                              <Col span={6}>
                                <Checkbox disabled={componentDisabler_complex()} value="fr" style={{lineHeight: '32px'}}>
                                  France
                                </Checkbox>
                              </Col>

                              <Col span={6}>
                                <Checkbox value="gb" style={{lineHeight: '32px'}}>
                                  United Kingdom
                                </Checkbox>
                              </Col>

                              <Col span={6}>
                                <Checkbox disabled={searchType} value="hu" style={{lineHeight: '32px'}}>
                                  Hungary
                                </Checkbox>
                              </Col>

                              <Col span={6}>
                                <Checkbox disabled={searchType} value="is" style={{lineHeight: '32px'}}>
                                  Iceland
                                </Checkbox>
                              </Col>

                              <Col span={6}>
                                <Checkbox value="it" style={{lineHeight: '32px'}}>
                                  Italy
                                </Checkbox>
                              </Col>

                              <Col span={6}>
                                <Checkbox disabled={componentDisabler()}
                                  value="lt" style={{lineHeight: '32px'}}>
                                  Lithuania
                                </Checkbox>
                              </Col>

                              <Col span={6}>
                                <Checkbox disabled={componentDisabler()} value="lv" style={{lineHeight: '32px'}}>
                                  Latvia
                                </Checkbox>
                              </Col>

                              <Col span={6}>
                                <Checkbox value="nl" style={{lineHeight: '32px'}}>
                                  Netherlands
                                </Checkbox>
                              </Col>

                              <Col span={6}>
                                <Checkbox disabled={searchType} value="pl" style={{lineHeight: '32px'}}>
                                  Poland
                                </Checkbox>
                              </Col>

                              <Col span={6}>
                                <Checkbox disabled={searchType} value="si" style={{lineHeight: '32px'}}>
                                  Slovenia
                                </Checkbox>
                              </Col>

                              <Col span={6}>
                                <Checkbox disabled={searchType} value="tr" style={{lineHeight: '32px'}}>
                                  Turkey
                                </Checkbox>

                                &nbsp;
                                <Tooltip title='Through which countries the search should be done.'>
                                    <InfoCircleOutlined/>
                                </Tooltip>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </Row>

                      <Row>
                        <Col>
                          <Form.Item name="ranges" label="Date range" required={!modusDisabled} {...rangeConfig}>
                            <RangePicker disabled={modusDisabled}/>
                          </Form.Item>
                        </Col>

                        <Col>
                          <Form.Item style={{float: "right"}} wrapperCol={{span: 12, offset: 6}}>
                            <Space>
                              <Button type="primary" htmlType="submit">Submit</Button>
                            </Space>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>                
                  </div>
              </div>
            </div>
            
            <SearchOutlined className="smallcardicon"/>
          </Card>
      </Col>
    </>
  );
};

export default App;