import React, {Component} from 'react';
import {Card, Skeleton} from "antd";

/**
 * @author Yavuz
 * @version 0.1
 * 
 * This page renders the about us page.
 **/

class About extends Component {
    render() {
        return (
            <Skeleton active={true} loading={false}> 
                <Card bodyStyle={{height: 'auto'}} bordered={false} style={{marginTop: 10}}>
                    <div className="alignment">
                        <div>
                            <div className="cardtitle">
                                <span>
                                    About AreYouRepresented
                                </span>
                            </div>

                            <div className="cardcontent_about">
                                <span>
                                    <p>
                                        Welcome to <span className="bluetext">AreYouRepresented</span>, your go-to destination for comprehensive parliamentary research and analysis across multiple European countries. We are proud to present this website, which was developed as part of a thesis project by <b>Yavuz Egilmez</b> and <b><a style={{textDecoration: "none", color: "inherit"}} href="mailto:M.J.Marx@uva.nl">Dr. M.J. Maarten Marx</a></b>. Inspired by the success and mission of platforms like <a href="https://www.theyworkforyou.com/">TheyWorkForYou</a>, <span className="bluetext">AreYouRepresented</span> aims to build upon that foundation and extend its reach to European countries. Our goal is to provide a user-friendly and efficient search engine that empowers users to explore the parliamentary landscapes of various European nations and gain valuable insights. The thesis can be read <a href="thesis/AreYouRepresented_Yavuz_Egilmez.pdf">here</a>.  
                                    </p>
                                    <p>
                                        We recognized the need for a centralized platform that not only presents parliamentary information but also optimizes the search experience. With the use of advanced technologies like <b>Elasticsearch</b> and <b>Python</b>, coupled with a sleek and intuitive <b>Ant Design</b> user interface, <span className="bluetext">AreYouRepresented</span> offers an enhanced user experience that facilitates in-depth search, research and analysis.  
                                    </p>
                                    <p>
                                        This website is a continuation of the vision set forth by <a href="https://www.theyworkforyou.com/">TheyWorkForYou</a>, adapting it to encompass parliamentary information from European countries with multiple languages. By expanding the scope of accessible data, we strive to foster greater transparency and engagement in the democratic processes of these nations.  We understand the importance of multilingualism in today's globalized world. Therefore, <span className="bluetext">AreYouRepresented</span> incorporates cross-lingual search capabilities, allowing users to seamlessly search through translated parliamentary texts and debate topics in English. This feature enables easy navigation across language barriers and provides a comprehensive view of the parliamentary landscape. It is crucial to note that the English translations used are machine-generated and not verified, meaning that they may not always be accurate.
                                    </p>
                                    <p>
                                        We are committed to continuously improving and expanding our platform. As a user, your <a href="/contact">feedback</a> is invaluable to us. We welcome your suggestions, comments, and ideas to enhance the functionality and usability of our search engine, ensuring that it remains a valuable resource for researchers, journalists, and engaged citizens. We extend our sincere gratitude to the <a href="https://www.clarin.eu/parlamint">ParlaMint</a> project, which enabled the existence of this website. 
                                    </p>
                                    <p>
                                        Thank you for choosing <span className="bluetext">AreYouRepresented</span>, and we hope you find our platform informative, insightful, and transformative in your parliamentary research endeavors. For more information and other questions, we refer you to our <a href="faq">FAQs</a>.
                                    </p>
                                    <p>
                                        The <a href="https://www.theparliamentmagazine.eu/news/article/this-week-in-the-european-parliament-23713">European Parliament photo</a> is of photographer Da&Iuml;na Le Lardic.<br></br>
                                        The <a href="https://nl.pinterest.com/pin/551128073154286333/">hands photo</a> is of AIGA Design.
                                    </p>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="bannerabout"></div>
                </Card>
            </Skeleton>
        );
    };
};

export default About;