import React, {Component} from 'react';
import axios from "axios";
import queryString from 'query-string'
import {Pagination, Skeleton} from "antd";
import Moment from 'moment';

let DebateTopicText = "";
let translatedstring = "";
let gender = { "M" : "Male", "F" : "Female", "-" : "Unknown gender"};
let url;

/**
 * @author Yavuz
 * @version 0.1
 * 
 * After selecting a result, the full session is shown using this page.
 **/

// Helper function to remove paragraphs if translated text and source text is the same.
const SimilarSpeechDataChecker = (value) => {
    if (value._source.Text === value._source.Text_tr) {
        return "";
    } else {
        return " <br/><i>Original source text: " + value._source.Text + "</i>";
    }
};

// Helper function to remove dates from titles to battle double date on page.
const re = /,?\s(?:\(|\b)(\d{4}|\d{1,2})[/.-](\d{1,2})[/.-](\d{4}|\d{2})(?:\)|\b)/;
const TitleDateCleaner = (value) => {
    return value.replace(re, "");
};

// Some party abbreviations are unknown, this removes them.
const UnknownRemoverAPS = (value) => {
    if (value !== "Unknown") {
        return " from " + value;
    } else {
        return "";
    }
};

// Some parties are unknown, this removes them.
const UnknownRemoverPS = (value) => {
    if (value !== "Unknown") {
        return " (" + value + ")";
    } else {
        return "";
    }
};

// This function retrieves the current parameters from the url.
const currentStateParameters = () => {
    const parsed = queryString.parse(document.location.search);
    return parsed;
};

// Helper function that determines where and whether to add debate topic texts into full speech sessions.
const Debater = (DebateTopic, TranslatedDebateTopic) => {
    if ((TranslatedDebateTopic !== null) && (DebateTopic !== "Unknown")) {
        if (DebateTopic !== DebateTopicText) {
            DebateTopicText = DebateTopic;
            if (translatedstring === "") {
                return "<b> Debate topic: </b> <i>" + DebateTopicText + "</i>";
            } else if (TranslatedDebateTopic === DebateTopic) {
                return "<b> Debate topic: </b> <i>" + DebateTopicText + "</i>";
            } else {
                return "<b> Translated debate topic: </b> <i>" + TranslatedDebateTopic + "</i> <br/> <i>Original source debate topic: " + DebateTopicText + "</i>";
            }
        } else {
            return "";
        }
    } else {
        return "";
    }
};

// Helper function that determines whether the speech texts are translated.
const TranslatedTexts = (value) => {
    if (value !== null) {
        return value;
    } else {
        return "Not translated";
    }
};

// The final component which retrieves the results from the backend, and renders it.
class Session extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            result: null,
            divs: [],
            pagelength: null,
            totalresults: null,
            page: null,
            pages: null,
            q: null,
            error: null,
        }
    };
    
    // Async method which retrieves the results from the backend using the given parameters in the url.
    // After that, the loading status of the page is set to false which now renders the page.
    DataLoader() {
        let parsedparams = currentStateParameters();
        let id = "";
        let onlyid = "";
        let pagequery = "";
        let translated = false;
        let translationquery = "";
        let debatequery = "";
        let debated = false;
        for (let i = 0; i < Object.keys(parsedparams).length; i++) {
            if (Object.keys(parsedparams)[i] === "text_ID") {
                onlyid = parsedparams[Object.keys(parsedparams)[i]];
                id = id + "?text_ID=" + parsedparams[Object.keys(parsedparams)[i]];
            } else if (Object.keys(parsedparams)[i] === "speech_ID") {
                onlyid = parsedparams[Object.keys(parsedparams)[i]];
                id = id + "?speech_ID=" + parsedparams[Object.keys(parsedparams)[i]];
            } else if (Object.keys(parsedparams)[i] === "p") {
                pagequery = pagequery + "&p=" + parsedparams[Object.keys(parsedparams)[i]];
            } else if (Object.keys(parsedparams)[i] === "trl" && parsedparams[Object.keys(parsedparams)[i]] === "true") {
                translated = true;
                translationquery = "&trl=true";
                translatedstring = "translated";
            } else if (Object.keys(parsedparams)[i] === "debate" && parsedparams[Object.keys(parsedparams)[i]] === "true") {
                debated = true;
                debatequery = "&debate=true";
            }
        }

        const baseurl = "https://api.areyourepresented.eu/session/";
        let final = id + debatequery + translationquery;
        const APIurl = baseurl + final + pagequery;
        url = "https://areyourepresented.eu/session/" + final;
        const DataRetriever = async () => {
            console.log(APIurl)
            const data = await axios.get(APIurl);
            try {
                this.setState({result: data.data.result.hits.hits, totalresults: data.data.result.hits.total.value, pagelength: data.data.pagelength, page: data.data.page, pages: data.data.pages, q: data.data.q});
            } catch (err) {
                // Handle errors here.
                console.error(err);
                this.setState({error: data.data});
            } finally {
                this.setState({loading: false});
                for (let i = 0; i < this.state.pagelength; i++) {
                    let tag1 = "";
                    let tag2 = "";
                    if (this.state.result[i]._source.ID === onlyid) {
                        tag1 = "<mark>";
                        tag2 = "</mark>";
                    }
                    const TempArray = [...this.state.divs];
                    let currentresult;
                    if (!debated) {
                        if (translated) {
                            currentresult = "<h1>" + Debater(this.state.result[i]._source.Debatetopic, this.state.result[i]._source.Debatetopic_tr) + "</h1>" + tag1 + "<b> <a href=../speeches/?q=" + this.state.result[i]._source.Speaker_name.replaceAll(" ", "%20") + "&mode=speaker&sort=n&trl=true>" + this.state.result[i]._source.Speaker_name + "</a> (" + gender[this.state.result[i]._source.Speaker_gender] + ", " + this.state.result[i]._source.Speaker_role + ")" + UnknownRemoverAPS(this.state.result[i]._source.Speaker_party_name) + UnknownRemoverPS(this.state.result[i]._source.Party_status) + ":</b> " + TranslatedTexts(this.state.result[i]._source.Text_tr) + SimilarSpeechDataChecker(this.state.result[i]) + "<br/>" + tag2;
                        } else {
                            currentresult = "<h1>" + Debater(this.state.result[i]._source.Debatetopic) + "</h1>" + tag1 + "<b> <a href=../speeches/?q=" + this.state.result[i]._source.Speaker_name.replaceAll(" ", "%20") + "&mode=speaker&sort=n>" + this.state.result[i]._source.Speaker_name + "</a> (" + gender[this.state.result[i]._source.Speaker_gender] + ", " + this.state.result[i]._source.Speaker_role + ")" + UnknownRemoverAPS(this.state.result[i]._source.Speaker_party_name) + UnknownRemoverPS(this.state.result[i]._source.Party_status) + ":</b> " + this.state.result[i]._source.Text + "<br/>" + tag2;
                        }
                    } else {
                        if (translated) {
                            currentresult = "<h1>" + tag1 + Debater(this.state.result[i]._source.Debatetopic, this.state.result[i]._source.Debatetopic_tr) + tag2 + "</h1><b> <a href=../speeches/?q=" + this.state.result[i]._source.Speaker_name.replaceAll(" ", "%20") + "&mode=speaker&sort=n&trl=true>" + this.state.result[i]._source.Speaker_name + "</a> (" + gender[this.state.result[i]._source.Speaker_gender] + ", " + this.state.result[i]._source.Speaker_role + ")" + UnknownRemoverAPS(this.state.result[i]._source.Speaker_party_name) + UnknownRemoverPS(this.state.result[i]._source.Party_status) + ":</b> " + TranslatedTexts(this.state.result[i]._source.Text_tr) + SimilarSpeechDataChecker(this.state.result[i]) + "<br/>";
                        } else {
                            currentresult = "<h1>" + tag1 + Debater(this.state.result[i]._source.Debatetopic) + tag2 + "</h1> <b> <a href=../speeches/?q=" + this.state.result[i]._source.Speaker_name.replaceAll(" ", "%20") + "&mode=speaker&sort=n>" + this.state.result[i]._source.Speaker_name + "</a> (" + gender[this.state.result[i]._source.Speaker_gender] + ", " + this.state.result[i]._source.Speaker_role + ")" + UnknownRemoverAPS(this.state.result[i]._source.Speaker_party_name) + UnknownRemoverPS(this.state.result[i]._source.Party_status) + ":</b> " + this.state.result[i]._source.Text + "<br/>";
                        }
                    }
                    TempArray.push(<div className="speakersmallcardcontent" dangerouslySetInnerHTML={{ __html: currentresult}} key={i}/>);
                    this.setState({divs: TempArray});
                }                
            }
        };

        DataRetriever();
    };

    // When component is mount, we make the API call.
    componentDidMount() {
        this.DataLoader();
    };

    // If there is another error than no hits, display that error as well.
    errorChecker() {
        if (this.state.error !== "No hits!") {
            return "Extra error information: " + this.state.error;
        } else {
            return "";
        }
    };

    // The final render is done, consisting of a title, debate topic, and speech. And respective translated texts if requested.
    render() {
        if (this.state.result !== null) {
            return (
                <Skeleton active={true} loading={this.state.loading}>
                    <h3 className="headhsspeakersmallcardcontent">Showing {translatedstring} speech {1+((this.state.page-1)*(this.state.pagelength))} - {((this.state.page-1)*(this.state.pagelength)) + this.state.divs.length} of {this.state.totalresults} for session <img src={"https://flagcdn.com/16x12/" + this.state.result[0]._index +".png"} alt={"flag_" + this.state.result[0]._index}/> <i>{TitleDateCleaner(this.state.result[0]._source.Title)} from {Moment(this.state.result[0]._source.From).format('D MMMM YYYY')}</i>:</h3>
                    <div>
                        {this.state.divs}
                        <br></br>
                        <br></br> 
                    </div>

                    <div style={{color: "green"}}>
                    <Pagination style={{margin: "auto", width: "50%"}} defaultCurrent={this.state.page} defaultPageSize={this.state.pagelength} total={this.state.totalresults} onChange={onChange} showSizeChanger={false} showQuickJumper/>
                    </div>
                </Skeleton>
            );
        } else {
            return (
            <Skeleton active={true} loading={this.state.loading}>
                <h1 className="headhsspeakersmallcardcontent">No results, try another query.</h1>
                <br></br>
                <div className="speakersmallcardcontent">
                    {this.errorChecker()}
                </div>
            </Skeleton>
            );
        }
    };
};

const onChange = (page) => {
    let redirecturl = url + "&p=" + page;
    window.open(redirecturl,"_self");
};

export default Session;