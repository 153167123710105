import React, {Component} from 'react';
import {Card, Skeleton} from "antd";
import {QuestionOutlined} from "@ant-design/icons";

/**
 * @author Yavuz
 * @version 0.1
 * 
 * The page with frequently asked questions is rendered here.
 **/

class Faq extends Component {
    render() {
        return (
            <Skeleton active={true} loading={false}> 
                <Card bodyStyle={{height: 'auto'}} bordered={false} style={{marginTop: 10}}>
                    <div className="alignment">
                        <div>
                            <div className="cardtitle">
                                <span>
                                    Frequently Asked Questions
                                </span>
                            </div>

                            <h1 className="faqstyle">General</h1>
                            <div className="cardcontent_about">
                                <h2 class="hsspeakersmallcardcontent" style={{marginBottom: 0}}>What is AreYouRepresented?</h2>
                                <div class="speakersmallcardcontent"><span className="bluetext">AreYouRepresented</span> is a parliamentary search engine that allows users to explore and analyze translated parliamentary speeches and debate topics from multiple European countries. It provides a user-friendly interface and <a href="/search">advanced search</a> capabilities to facilitate in-depth research and analysis.</div>
                                
                                <h2 class="hsspeakersmallcardcontent" style={{marginBottom: 0}}>How does AreYouRepresented work?</h2>
                                <div class="speakersmallcardcontent">We index and organize parliamentary speeches from various European countries into a searchable database. This <a href="/data">indexed data</a> is retrieved from the ParlaMint repository. Users can then enter keywords, names of politicians, or specific topics to search for relevant speeches in the search engine.</div>

                                <h2 class="hsspeakersmallcardcontent" style={{marginBottom: 0}}>What is the difference between a search in speeches and search in debate topics?</h2>
                                <div class="speakersmallcardcontent">Users have two search options: one is a search through each individual speech made by every politician in the database, and the other is a search through debate topics of parliamentary sessions. The individual speeches are complete in the corpora; however, the debate topics are not total yet. Denmark, Spain, France, the United Kingdom, Italy, and the Netherlands have notes on the subject of their parliamentary sessions, which are extracted for search on debate topics. When more data is made available by ParlaMint, our database will be updated, and hopefully, support for search on debate topics for more countries will increase.</div>

                                <h2 class="hsspeakersmallcardcontent" style={{marginBottom: 0}}>How does the searching modes work?</h2>
                                <div class="speakersmallcardcontent">There are four different modes available. The first is a standard search modus, which searches through all texts and accepts fuzziness and typos in the query. The second search is an exact search, where you can search for exact words or phrases. Here, no fuzziness is accepted, and your query should be perfect. Range search is the third modus, where you can search for results between two dates. At last, we have a speaker search modus, where you can search the speeches made by a speaker.</div>
                                
                                <h2 class="hsspeakersmallcardcontent" style={{marginBottom: 0}}>Hoes does a cross-lingual search work?</h2>
                                <div class="speakersmallcardcontent">By doing a cross-lingual search, the results will only contain countries with available translated texts. This way, a search through multiple languages can be done with one English query. Eleven of the sixteen countries in the database have translated speeches into English. This option means that users can search in English through the translated texts, resulting in a multilingual search. The supported translated languages are Bulgarian, Czech, Danish, Hungarian, Icelandic, Italian, Dutch, Polish, Slovene, and Turkish.</div>

                                <h2 class="hsspeakersmallcardcontent" style={{marginBottom: 0}}>What are the supported countries?</h2>
                                <div class="speakersmallcardcontent">We currently cover a range of European countries, including Belgium, Bulgary, Czechia, Denmark, Spain, France, United Kingdom, Hungary, Iceland, Italy, Lithuania, Latvia, the United Kingdom, the Netherlands, Poland, Slovenia, and Turkey. When the ParlaMint repository is updated, we will also update our database.</div>

                                <h2 class="hsspeakersmallcardcontent" style={{marginBottom: 0}}>Is AreYouRepresented free to use?</h2>
                                <div class="speakersmallcardcontent">Yes, we are free to use and will stay that way. We believe in promoting open access to parliamentary information and enabling widespread engagement with democratic processes. </div>
                            </div>
                        </div>
                    </div>
                    
                    <QuestionOutlined className="smallcardicon"/>
                </Card>
            
                <Card bodyStyle={{height: 'auto'}} bordered={false} style={{marginTop: 10}}>
                    <div className="alignment">
                        <div>
                            <h1 className="faqstyle" style={{marginTop: -10}}>Content</h1>
                            <div className="cardcontent_about">
                                <h2 class="hsspeakersmallcardcontent" style={{marginBottom: 0}}>Where does the data come from?</h2>
                                <div class="speakersmallcardcontent">The data used in this search engine comes from the <a href="https://www.clarin.eu/parlamint">ParlaMint</a> repository, a well-known dataset specifically designed for parliamentary research and analysis. ParlaMint provides a rich collection of parliamentary speeches and related metadata from multiple European countries. The dataset encompasses a wide range of topics and is continuously updated to include new speeches and sessions.</div>
                                
                                <h2 class="hsspeakersmallcardcontent" style={{marginBottom: 0}}>How frequently is the database updated?</h2>
                                <div class="speakersmallcardcontent">When ParlaMint releases new supported countries and translated languages, we will directly update them as well.</div>
                                
                                <h2 class="hsspeakersmallcardcontent" style={{marginBottom: 0}}>Do you have any random facts?</h2>
                                <div class="speakersmallcardcontent">Ofcourse, our oldest document in the database comes from 28 June 2011, and the newest is from 23 April 2021. The Turkish parliament talks the most, and the Hungarian parliament talks the least.</div>
                            </div>
                        </div>
                    </div>

                    <QuestionOutlined className="smallcardicon"/>
                </Card>
            </Skeleton>
        );
    };
};

export default Faq;