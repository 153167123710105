import "../../css/App.css"
import React from 'react';
import {Button, Form, Input, Row, Select, Space, Switch} from 'antd';
import queryString from 'query-string'
import {MailOutlined} from "@ant-design/icons";

const { Option } = Select;
let disableddebate;
let disabledtranslation;

/**
 * @author Yavuz
 * @version 0.1
 *
 * Header component for a simple searchbar
 **/

// Controller function directing the input query into the search engine.
const onFinish = (values) => {  
  let finalquery = "";
  for (let i = 0; i < Object.keys(values).length; i++) {
    if (typeof(values[Object.keys(values)[i]]) !== "undefined" && Object.keys(values)[i] !== "ranges") {
      if (Object.keys(values)[i] === "q") {
        finalquery = finalquery + "?q=" + values[Object.keys(values)[i]];
      }
    }
  }

  if (finalquery.length === 0) {
    window.open("/search", "_self");
  } else if (disableddebate && disabledtranslation) {
    window.open("/debates/" + finalquery + "&trl=true&debate=true&country=dk,es,fr,gb,it,nl", "_self");
  } else if (!disableddebate && disabledtranslation) {
    window.open("/speeches/" + finalquery + "&trl=true&country=bg,cz,dk,gb,hu,is,it,nl,pl,si,tr", "_self");
  } else if (disableddebate && !disabledtranslation) {
    window.open("/debates/" + finalquery + "&debate=true&country=dk,es,fr,gb,it,nl", "_self");
  } else if (!disableddebate && !disabledtranslation) {
    window.open("/speeches/" + finalquery, "_self");
  }
};
  
// Helper functions for disabling/enabling some components when a button has been selected.
const switchSearch = () => {
  if (disableddebate) {
    disableddebate = false;
  } else {
    disableddebate = true;
  }
};

const currentDebateRetriever = () => {
  if (!disableddebate) {
    return "false";
  } else {
    return "true";
  }
};

const switchCrosslingual = () => {
  if (disabledtranslation) {
    disabledtranslation = false;
  } else {
    disabledtranslation = true;
  }  
};

// This function retrieves the current parameters from the url.
const currentStateParameters = () => {
  const parsed = queryString.parse(document.location.search);

  if (Object.keys(parsed).length === 0) {
    disabledtranslation = true;
    disableddebate = false;
  }

  for (let i = 0; i < Object.keys(parsed).length; i++) {
    if (Object.keys(parsed)[i] === "trl" && parsed[Object.keys(parsed)[i]] === "true") {
      disabledtranslation = true;
    } else if (Object.keys(parsed)[i] === "trl" && parsed[Object.keys(parsed)[i]] === "false") {
      disabledtranslation = false;
    } else if (Object.keys(parsed)[i] === "debate" && parsed[Object.keys(parsed)[i]] === "true") {
      disableddebate = true;
    } else if (Object.keys(parsed)[i] === "debate" && parsed[Object.keys(parsed)[i]] === "false") {
      disableddebate = false;
    } else {
      if (typeof(disabledtranslation) === 'undefined') {
        disabledtranslation = false;
      }
      if (typeof(disableddebate) === 'undefined') {
        disableddebate = false;
      }
    }
  }
};

// Render of the header search with all the form items.
const headersearchbar = () => {
  return (
    <>
      {currentStateParameters()}
      
      <div className="headersearchbar">
        <div className="headersearch_text" style={{textAlign: "center"}}>
          &nbsp;
          <a href="/dashboard" style={{display: "inline-block"}}>AYR</a>
          &nbsp;
        </div> 
      
        <div className="headersearch_searchbar">
          <Form style={{maxWidth: 1000}} onFinish={onFinish}>  
            <Row>
              <Form.Item style={{width: "13em"}} label={<label style={{color: "#5a5c69"}}>Search</label>} name="debate">
                <Select defaultValue={currentDebateRetriever()} onChange={()=>{switchSearch()}}>
                  <Option value="false">speeches</Option>
                  <Option value="true">debate topics</Option>
                </Select>
              </Form.Item>

              <Space>
                &nbsp;&nbsp; 
              </Space>
              
              <Form.Item label={<label style={{color: "#5a5c69"}}>Cross-lingual</label>} name="trl">
                <Switch defaultChecked={disabledtranslation} checkedChildren="yes" unCheckedChildren="no" onChange={()=>{switchCrosslingual()}}/> 
              </Form.Item>

              <Space>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
              </Space>

              <Form.Item name="q">
                <Input placeholder="e.g. a topic or person"/>
              </Form.Item> 

              <Form.Item wrapperCol={{ span: 1, offset: 1 }}>
                  <Button type="primary" htmlType="submit">Search</Button>
              </Form.Item>
            </Row>  
          </Form>
        </div>

        <div style={{float:"right", marginLeft:"auto", marginRight: 20, marginTop: 10}}>
          <Button href="/contact" type="default" shape="square" icon={<MailOutlined/>} style={{color: "#5a5c69"}} size={"default"}>Contact</Button>
        </div>      
      </div>
    </>
  );
};

export default headersearchbar;